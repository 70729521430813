import { HStack, Stack, Text } from 'native-base';
import { ScrollView } from 'react-native';

import { CarouselItem } from '../CarouselItem';
import { Pill, PillSize, PillStyle } from '../Pill';

import { OrderProduct, ProductType } from '@/api';
import { capitalize, getMealRecipeName, getRecipesProductImageSrc } from '@/utils';

export const OrderProductCarousel = ({ orderProducts }: { orderProducts: OrderProduct[] }) => {
  return (
    <HStack alignItems="center" position="relative" space={{ base: '0px', lg: '48px' }}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <HStack
          w="100%"
          maxW={{ lg: '840px' }}
          alignSelf={{
            md: 'center',
          }}
          justifyContent="start"
          alignItems="start"
          space={{ base: 2, md: 4 }}
        >
          {orderProducts?.map((oProduct) => {
            const recipes = oProduct.recipes.map((recipe) => {
              return { id: recipe.id, type: recipe.type };
            });
            const imageUrl = getRecipesProductImageSrc(recipes);
            const description =
              oProduct.product_type === ProductType.MEAL
                ? getMealRecipeName(oProduct.recipes[0].type)
                : capitalize(oProduct.recipes[0].type);
            return (
              <Stack key={oProduct.id} w="fit-content">
                {oProduct.quantity > 1 ? (
                  <Pill
                    style={PillStyle.Quantity}
                    size={PillSize.ExtraSmall}
                    position="absolute"
                    top={1}
                    right={1}
                    zIndex={999}
                  >
                    <Text size="bodyMd" fontWeight="bold">
                      x {oProduct.quantity}
                    </Text>
                  </Pill>
                ) : null}
                <CarouselItem
                  price=""
                  maxW="140px"
                  title={oProduct.recipes
                    .map((recipe) => recipe.name)
                    .sort()
                    .join(', ')}
                  titleProps={{ size: 'bodyMl' }}
                  description={description}
                  descriptionProps={{ fontFamily: 'primary', size: 'caption', fontWeight: 400 }}
                  image={imageUrl}
                  imageProps={{ bg: 'white', size: '140px' }}
                  isLoading={false}
                  m={0}
                />
              </Stack>
            );
          })}
        </HStack>
      </ScrollView>
    </HStack>
  );
};
