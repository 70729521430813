import {
  Heading,
  Text,
  Factory,
  Pressable,
  VStack,
  IImageProps,
  Badge,
  IHeadingProps,
  Button,
  HStack,
  Stack,
  IPressableProps,
  IButtonProps,
  IBadgeProps,
  ITextProps,
} from 'native-base';
import { forwardRef, useState } from 'react';

import { Image } from '@/components/Elements';
import { roundPriceShort } from '@/utils';

export type CarouselItemProps = {
  title: string;
  image: string;
  description?: string;
  primaryCtaText?: string;
  price?: number | string;
  unit?: string;
  quantity?: number | string;
  onAdd?: () => void;
  onRemove?: () => void;
  onPressImage?: () => void;
  onEditQuantity?: () => void;
  isLongPressAvailable?: boolean;
  hoverStateImage?: string;
  isLoading?: boolean;
  plusDisabledMessage?: string;
  badgeMessage?: IBadgeProps['children'];
  titleProps?: IHeadingProps;
  descriptionProps?: ITextProps;
  imageProps?: IImageProps;
  pressableProps?: IPressableProps;
  primaryCtaProps?: IButtonProps;
};

export const MyCarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(
  (
    {
      title,
      image,
      description,
      primaryCtaText = 'ADD TO PLAN',
      price,
      unit,
      quantity,
      isLongPressAvailable = false,
      hoverStateImage = '',
      isLoading = false,
      plusDisabledMessage,
      badgeMessage,
      onAdd,
      onRemove = () => {},
      onPressImage,
      onEditQuantity,
      imageProps,
      pressableProps,
      titleProps,
      descriptionProps,
      primaryCtaProps,
      ...props
    },
    ref
  ) => {
    const [showHoverStateImage, setShowHoverStateImage] = useState(false);

    const toggleHoverImage = () =>
      isLongPressAvailable ? setShowHoverStateImage((prev) => !prev) : null;

    const quantityTextStyle = {
      fontFamily: 'secondary',
      size: 'bodySmToMd',
      color: 'sntGrey.primary',
      fontWeight: 'bold',
    };

    return (
      <VStack
        ref={ref}
        justifyContent="start"
        alignItems="center"
        h="100%"
        space={{ base: '8px', lg: '16px' }}
        maxW={{ lg: '265px' }}
        {...props}
      >
        <Pressable
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderWidth="0px"
          _hover={{ borderWidth: '0px' }}
          p="0px"
          h="fit-content"
          onPress={() => onPressImage?.()}
          onLongPress={toggleHoverImage}
          onHoverIn={toggleHoverImage}
          onHoverOut={toggleHoverImage}
          {...pressableProps}
        >
          {badgeMessage ? (
            <Badge
              variant="smallTabActive"
              pointerEvents="none"
              position="absolute"
              zIndex={1}
              top={2}
              right={2}
              px={1}
            >
              {badgeMessage}
            </Badge>
          ) : null}
          <Image
            alt={title}
            size="265px"
            source={{ uri: isLongPressAvailable && showHoverStateImage ? hoverStateImage : image }}
            resizeMode="contain"
            bg="sntGrey.athens"
            {...imageProps}
          />
        </Pressable>
        <VStack justifyContent="center" alignItems="center" w="100%" space={2}>
          <Heading size="bodyLg" fontWeight="bold" textAlign="center" {...titleProps}>
            {title}
          </Heading>

          {description ? (
            <Text
              size="bodySmToMd"
              fontWeight="medium"
              fontFamily="secondary"
              mb={2}
              textAlign="center"
              {...descriptionProps}
            >
              {description}
            </Text>
          ) : null}

          {price ? (
            <Text size="bodyMd" fontWeight="medium" color="black">
              {`$${roundPriceShort(price)}${unit ? '/' + unit : ''}`}
            </Text>
          ) : null}

          {onAdd ? (
            <Stack px={{ lg: 4 }} w="100%" alignItems="center">
              <Button
                variant="primary"
                px={0}
                w="100%"
                onPress={onAdd}
                isLoading={isLoading}
                {...primaryCtaProps}
              >
                {primaryCtaText}
              </Button>
            </Stack>
          ) : null}

          {quantity && Number(quantity) > 0 ? (
            <HStack>
              <Text {...quantityTextStyle}>{quantity} added</Text>
              {onEditQuantity ? (
                <Text {...quantityTextStyle}>
                  {' '}
                  -{' '}
                  <Button
                    _text={{
                      fontFamily: 'secondary',
                      size: 'bodySmToMd',
                      color: 'sntGrey.primary',
                    }}
                    variant="underlineMini"
                    onPress={onEditQuantity}
                  >
                    Edit
                  </Button>
                </Text>
              ) : null}
            </HStack>
          ) : null}
        </VStack>
      </VStack>
    );
  }
);

export const CarouselItem = Factory(MyCarouselItem);
