import { Meta, Story } from '@storybook/react';
import { Stack } from 'native-base';
import { useState } from 'react';

import { mockRecipeSnack1 } from '../../__mocks__';
import { CarouselItem, CarouselItemProps } from './CarouselItem';

import { ASSETS_URL } from '@/constants';
import { capitalize } from '@/utils';
const PICTOS_URL = `${ASSETS_URL}/pictos`;

const meta: Meta = {
  title: 'Components/Elements/CarouselItem',
  component: CarouselItem,
  parameters: {
    controls: { expanded: true },
  },
};

export default meta;

const Template: Story<CarouselItemProps> = (props) => {
  const [quantity, setQuantity] = useState(0);

  return (
    <Stack w={{ base: '150px', lg: '250px' }} alignItems="center">
      <CarouselItem
        {...props}
        quantity={quantity}
        onAdd={() => setQuantity((x) => x + 1)}
        onRemove={() => setQuantity((x) => (x > 0 ? x - 1 : 0))}
      />
    </Stack>
  );
};

export const CalmingSupplement = Template.bind({});
CalmingSupplement.args = {
  isLoading: false,
  title: 'Calming',
  price: '26',
  image: `${PICTOS_URL}/Supplements/calming.png`,
};

export const ChickenMunchies = Template.bind({});
ChickenMunchies.args = {
  isLoading: false,
  title: mockRecipeSnack1.name,
  price: mockRecipeSnack1.default_product.price.price,
  image: `${PICTOS_URL}/${capitalize(mockRecipeSnack1.type)}/${mockRecipeSnack1.id}.png`,
  badgeMessage: 'NEW',
};

export const ChickenMunchiesHoverState = Template.bind({});
ChickenMunchiesHoverState.args = {
  isLoading: false,
  title: mockRecipeSnack1.name,
  isLongPressAvailable: true,
  hoverStateImage: `${PICTOS_URL}/${capitalize(mockRecipeSnack1.type)}/${
    mockRecipeSnack1.id
  }-HS.png`,
  price: mockRecipeSnack1.default_product.price.price,
  image: `${PICTOS_URL}/${capitalize(mockRecipeSnack1.type)}/${mockRecipeSnack1.id}.png`,
};
