import { datadogLogs } from '@datadog/browser-logs';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getInitialURL } from 'expo-linking';
import { View, Flex, Stack, VStack, Text } from 'native-base';
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types';
import { useEffect, useState } from 'react';

import { LeftArrow } from '../Icons';
import { DiscountBanner } from './DiscountBanner';

import { Breadcrumbs, Button, Image } from '@/components/Elements';
import { FunnelScreenNames, FunnelScreens, ASSETS_URL } from '@/constants';
import {
  useAxiosStatusContext,
  useCountdownTimerContext,
  useDogsLastVisitedScreenContext,
} from '@/context';
import { useSessionQuery } from '@/hooks';
import { FunnelStackParamList } from '@/types';

dayjs.extend(duration);
const { logger } = datadogLogs;

interface HeadProps extends IViewProps {
  showDiscountBanner?: boolean;
}

export const Head = ({ showDiscountBanner, ...props }: HeadProps) => {
  const { session, mutateUpdateSession } = useSessionQuery();
  const navigation = useNavigation<NavigationProp<FunnelStackParamList>>();
  const route = useRoute();
  const [dogIndex, setDogIndex] = useState<number>(session?.current_pet_index || 0);
  const [didLogPetIndexError, setDidLogPetIndexError] = useState(false);
  const routeName = route.name as FunnelScreenNames;
  const { addAxiosPromise } = useAxiosStatusContext();

  const { dogsVisitedScreens } = useDogsLastVisitedScreenContext();

  const { countdownTimerValue, start, restart, visibilityOfTimer, timerStarted } =
    useCountdownTimerContext();

  const handleGoBack = async () => {
    const screenConfig = FunnelScreens.find((screen) => screen.name === routeName);
    if (!screenConfig?.previousStep) {
      return navigation.goBack();
    }

    return navigation.navigate(screenConfig.previousStep as any);
  };

  useEffect(() => {
    setDogIndex(session?.current_pet_index || 0);
    (async () => {
      const initialUrl = await getInitialURL();
      const isFunnelPage = initialUrl && initialUrl.includes('/signup');

      const shouldHavePetIndex = (routeName: string) =>
        ![
          FunnelScreenNames.NAME_AND_EMAIL,
          FunnelScreenNames.HOW_MANY_DOGS,
          FunnelScreenNames.DOG_NAME,
          FunnelScreenNames.PROCESSING_ORDER,
          FunnelScreenNames.CHECKOUT,
          FunnelScreenNames.PROCESSING_ORDER,
          FunnelScreenNames.POST_CHECKOUT,
          FunnelScreenNames.WELCOME_SCREEN,
        ].includes(routeName as FunnelScreenNames);
      if (
        isFunnelPage &&
        shouldHavePetIndex(route.name) &&
        session?.current_pet_index === undefined &&
        !didLogPetIndexError
      ) {
        logger.error(
          `Head.tsx: current_pet_index not found. Fallback to default pet index 0. Session id: ${session?.id}; user email: ${session?.email}; route name: ${route.name}`
        );
        setDidLogPetIndexError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const showBackButton = ![
    FunnelScreenNames.NAME_AND_EMAIL,
    FunnelScreenNames.PROCESSING_ORDER,
    FunnelScreenNames.POST_CHECKOUT,
    FunnelScreenNames.WELCOME_SCREEN,
  ].includes(routeName);
  const showDogPills = ![
    FunnelScreenNames.NAME_AND_EMAIL,
    FunnelScreenNames.HOW_MANY_DOGS,
    FunnelScreenNames.PROCESSING_ORDER,
    FunnelScreenNames.CHECKOUT,
    FunnelScreenNames.POST_CHECKOUT,
  ].includes(routeName);

  const showCountdownTimer = timerStarted && visibilityOfTimer(routeName);

  useEffect(() => {
    if (routeName === FunnelScreenNames.DOG_NAME) {
      restart();
    }
  }, [routeName, restart]);

  // start timer if funnel is reloaded
  useEffect(() => {
    if (visibilityOfTimer(routeName)) {
      start();
    }
  }, [start, routeName, visibilityOfTimer]);

  const partOfTextInTimer = session?.pets && session?.pets.length > 1 ? 's have' : ' has';

  const selectDog = async (index: number) => {
    const dogLastVisitedScreen = dogsVisitedScreens[index];
    if (index !== dogIndex && dogLastVisitedScreen) {
      const customPromise = new Promise<void>((resolve) => {
        const updateSession = async () => {
          await mutateUpdateSession({
            data: {
              current_pet_index: index,
            },
          });

          navigation.navigate(dogLastVisitedScreen as any);
          resolve();
        };
        updateSession();
      });

      addAxiosPromise(customPromise);
    }
  };

  return (
    <View
      bgColor="white"
      position="relative"
      w="100%"
      overflow="hidden"
      mb={{ base: '24px', md: '64px' }}
      {...props}
    >
      <Flex
        bg="white"
        paddingTop={{ base: '16px', lg: '36px' }}
        justifyContent="center"
        direction="row"
        alignItems="center"
      >
        <Stack
          position="absolute"
          top={{ base: '17px', lg: '38px' }}
          left={{ base: '16px', lg: '84px' }}
        >
          {showBackButton ? (
            <Button
              padding="0px"
              variant="tertiary"
              startIcon={<LeftArrow color="black" size={5} />}
              onPress={handleGoBack}
              _text={{
                fontSize: { base: '12px', md: '16px' },
                lineHeight: { base: '16px', lg: '20px' },
              }}
              size={{ base: 'caption', lg: 'bodyMd' }}
            >
              Back
            </Button>
          ) : null}
        </Stack>
        <Stack h={{ base: '22px', lg: '' }} justifyContent="center" alignItems="center">
          <Image
            width={{ base: '126px', lg: '282px' }}
            height={{ base: '30px', lg: '64px' }}
            source={{
              uri: `${ASSETS_URL}/logo.svg`,
            }}
            alt="Spot & Tango Logo"
            resizeMode="contain"
          />
        </Stack>
      </Flex>
      {showCountdownTimer && (
        <VStack
          justifyContent="center"
          alignSelf="center"
          mt={{ base: '16px', md: '24px' }}
          py="12px"
          px="16px"
          backgroundColor="gallery"
          w={{ base: '312px', md: '508px' }}
        >
          <Text textAlign="center" size="bodySmToMd" fontFamily="secondary" fontWeight="medium">
            {`Your personalized meal plan${partOfTextInTimer} been reserved for ${dayjs
              .duration(countdownTimerValue * 1000)
              .format('m:ss')} minutes`}
          </Text>
        </VStack>
      )}
      {showDogPills && session?.pets && (
        <VStack alignItems="center" space={2}>
          {session?.pets?.length > 1 && session?.pets[dogIndex]?.name && (
            <Breadcrumbs
              currentDog={dogIndex}
              dogs={session?.pets}
              selectDog={selectDog}
              mt={{ base: '16px', lg: '24px' }}
            />
          )}
        </VStack>
      )}
      {showDiscountBanner ? <DiscountBanner /> : null}
    </View>
  );
};
