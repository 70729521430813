import { useIsFocused } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Stack, Text, Divider, Button, HStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';

import { PlanSummaryCard, FeedingGuidelinesCard } from '.';
import segment from '../../../segment';
import DeceasedDogsView from '../DeceasedDogsView';
import { AddNewDogButton } from './AddNewDogButton';
import { DogTabSkeleton } from './DogTabSkeleton';

import { INACTIVE_STATUSES, PetStatus } from '@/api';
import { EditableCard, PortalWrapper, DogPictoGroup } from '@/components/Elements';
import { PillCarousel } from '@/components/Elements/PillCarousel';
import { AddOnCarousel } from '@/components/Portal/AddOnShopping';
import { useAccount, useRecommendedAddons, useDogModal } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import {
  checkIfAllPetsAreDeceased,
  getPetsFulfillments,
  sortOrderByScheduled,
  sortPetsAlphabetically,
} from '@/utils';

export const DogsTab = ({ navigation, route }: ProtectedScreenProps<'Dogs'>) => {
  const account = useAccount();
  const [selectedPetId, setSelectedPetId] = useState<string>();
  const allPetsAreDeceased = checkIfAllPetsAreDeceased(account.pets);
  const sortedPets = useMemo(() => sortPetsAlphabetically(account.pets), [account.pets]);
  const filteredPets = useMemo(
    () => sortedPets.filter((pet) => pet.status !== PetStatus.DECEASED_TO_NOT_LIST),
    [sortedPets]
  );
  const activePets = useMemo(
    () => filteredPets.filter((pet) => pet.status === PetStatus.ACTIVE),
    [filteredPets]
  );

  const selectedPet = filteredPets.find((pet) => pet.id === selectedPetId);

  const availableAddons = useRecommendedAddons();

  const petsFulfillments = getPetsFulfillments(
    [...account.orders.past, ...account.orders.processing],
    selectedPet
  );

  const { Modal: UpdateDogModal, setShowModal: showUpdateDogModal } = useDogModal('update');
  const { Modal: AddDogModal, setShowModal: setShowAddDogModal } = useDogModal('add');

  // select the first pet (or URL param pet if exists) on focus change
  const isFocused = useIsFocused();
  useEffect(() => {
    if (filteredPets.length && !allPetsAreDeceased) {
      const petByParam = filteredPets.find((pet) => pet.id === route.params?.petId);
      const pet = petByParam || activePets[0] || filteredPets[0];
      setSelectedPetId(pet.id);
    }
  }, [isFocused, activePets, allPetsAreDeceased, filteredPets, route.params?.petId]);

  useEffect(() => {
    if (route.params?.scrollToTop) {
      window.scrollTo(0, 0);
    }
    if (route.params?.componentToRedirect === 'AdjustPortionSize' && route.params.petId) {
      navigation.push('AdjustPortionSize', {
        petId: route.params.petId,
        successRedirect: 'Dogs',
        successRedirectParams: { petId: route.params.petId },
      });
    }
  }, [
    navigation,
    route.params?.componentToRedirect,
    route.params?.petId,
    route.params?.scrollToTop,
  ]);

  if (allPetsAreDeceased) {
    return (
      <PortalWrapper>
        <DeceasedDogsView />
      </PortalWrapper>
    );
  }

  if (!selectedPet) return null;

  const inactivePets = filteredPets
    .filter((pet) => INACTIVE_STATUSES.includes(pet.status))
    .map((pet) => ({
      name: pet.name,
      label: pet.name,
      id: pet.id,
      type: 'inactive',
    }));

  const dogItems = activePets
    .map((pet) => ({
      name: pet.name,
      label: pet.name,
      id: pet.id,
    }))
    .concat(inactivePets);
  const selectedPetIsInactive = INACTIVE_STATUSES.includes(selectedPet.status);

  const petPlan = selectedPetId
    ? account.petplans.find(({ pet: petId }) => petId === selectedPetId)
    : undefined;
  const futureOrders = [...account.orders.processing, ...account.orders.upcoming]
    .filter((order) => !order.charged)
    .sort(sortOrderByScheduled);
  const nextOrder = futureOrders.find((order) =>
    order.products.some((product) => product.pet_plan?.id === petPlan?.id)
  );
  const petIsActive = selectedPet?.status === PetStatus.ACTIVE;

  const petLifestyleAvailable = selectedPet?.properties?.lifestyle !== null;
  const petBirthYearAvailable = selectedPet?.birth_year !== null;
  const petBreedAvailable = selectedPet?.breed_details && selectedPet.breed_details.length > 0;
  const petWeightAvailable = selectedPet?.properties?.weight;
  const allPetDataAvailable =
    selectedPet &&
    petBreedAvailable &&
    petBirthYearAvailable &&
    petWeightAvailable &&
    petLifestyleAvailable;

  if (!selectedPet || !petPlan) {
    return <DogTabSkeleton />;
  }

  const onPressAddNewDog = () => {
    segment.trackEvent('Click Add New Dog', { email: account.email, account_id: account.id });
    setShowAddDogModal(true);
  };

  const onPressUpdateDog = () => {
    segment.trackEvent('Click Update Dog Details', {
      email: account.email,
      account_id: account.id,
      pet_id: selectedPet.id,
      pet_name: selectedPet.name,
    });
    showUpdateDogModal(true);
  };

  // TODO: Remove after Shop Tab launch
  const showShopTab = process.env.SHOW_SHOP_TAB === 'true';
  const carouselTitle = showShopTab
    ? 'S&T Shop — Bestsellers'
    : `Recommended for ${account.pets?.length === 1 ? account.pets[0].name : 'You'}`;
  const carouselDescription = showShopTab ? 'Exclusively for Spot & Tango subscribers' : undefined;

  return (
    <>
      <AddNewDogButton onPress={onPressAddNewDog} />
      <PillCarousel
        selectedItem={{ ...selectedPet, label: selectedPet.name }}
        setSelectedItemId={(id) => {
          navigation.setParams({ petId: id });
          setSelectedPetId(id);
        }}
        selectedItemIsInactive={selectedPetIsInactive}
        items={dogItems}
      />
      <PortalWrapper>
        <Stack space={{ base: 4, lg: 6 }} justifyContent="center" alignItems="center" w="100%">
          <Stack
            alignItems="center"
            space={{ base: '24px', lg: '48px' }}
            w={{ base: '100%', lg: '344px' }}
          />
          {allPetDataAvailable && (
            <>
              <DogPictoGroup selectedPet={selectedPet} isDisabled={!petIsActive} />
              <Stack>
                <Button
                  onPress={onPressUpdateDog}
                  variant="underlineMini"
                  isDisabled={!petIsActive}
                >
                  {`Update ${selectedPet.name}'s Profile`}
                </Button>
              </Stack>
            </>
          )}
          <Divider bg="gallery" />
          {nextOrder && (
            <EditableCard
              heading="Next Order"
              onPress={() =>
                navigation.navigate('Orders', {
                  orderId: nextOrder.id,
                  successRedirect: 'Dogs',
                })
              }
            >
              <HStack alignSelf="center">
                <Text size="bodyMd">{dayjs(nextOrder.scheduled).local().format('MMM D')}</Text>
              </HStack>
            </EditableCard>
          )}
          <PlanSummaryCard pet={selectedPet} petPlan={petPlan} disabled={!petIsActive} />
          {selectedPetId && petsFulfillments.length ? (
            <FeedingGuidelinesCard petId={selectedPetId} />
          ) : null}
          {petIsActive ? (
            <AddOnCarousel
              availableAddons={availableAddons}
              title={carouselTitle}
              description={carouselDescription}
              selectedPetIds={selectedPetId ? [selectedPetId] : undefined}
            />
          ) : null}
        </Stack>

        <UpdateDogModal />
        <AddDogModal />
        {!petIsActive ? (
          <Stack
            position="sticky"
            w="100%"
            bottom="0"
            left="0"
            right="0"
            alignItems="center"
            px={4}
            py={6}
            space={{ base: 2, lg: 6 }}
            bg="white"
          >
            <Text fontWeight="bold" size="bodyMdToLg" textAlign="center">
              Don't worry, you can make changes to your plan before confirming reactivation.
            </Text>
            <Button
              onPress={() => navigation.navigate('ReactivatePlan', { petId: selectedPet.id })}
            >
              REACTIVATE
            </Button>
          </Stack>
        ) : null}
      </PortalWrapper>
    </>
  );
};
