import { datadogLogs } from '@datadog/browser-logs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { Box, HStack, Stack, Text } from 'native-base';
import { useMemo, useState } from 'react';

import { DogSelectModal } from '../DogSelectModal';
import { SectionContent } from './ShopTab';

import { RecipeFull, RecipeType, useAddProductToPetPlan } from '@/api';
import { CarouselItem, displayToast, PDPModal, ToastType, VToHStack } from '@/components/Elements';
import SvgIllustratedStar from '@/components/Icons/IllustratedStar';
import { ADDONS_CONFIGURATION, AddonsType, ASSETS_URL } from '@/constants';
import { useAccount, useActivePets, useAuth, useIsMobile } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import { capitalize, getAddOnQuantitiesByPet, getFirstPetWithAddon } from '@/utils';

const { logger } = datadogLogs;

const StarBadge = ({ badgeContent }: { badgeContent: string }) => {
  return (
    <HStack alignItems="center" space={1}>
      <SvgIllustratedStar w={{ base: 3, lg: 4 }} h={{ base: 3, lg: 4 }} />
      <Text size="bodySmToMd" fontWeight="bold">
        {badgeContent}
      </Text>
    </HStack>
  );
};

type AddOnItemsProps = {
  recipes: RecipeFull[];
  itemsRef: SectionContent['itemsRef'];
  bannerRef?: { recipeId: RecipeFull['id']; ref: React.RefObject<HTMLDivElement> };
};

export const AddOnItems = ({ recipes, itemsRef, bannerRef }: AddOnItemsProps) => {
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const isMobile = useIsMobile();
  const account = useAccount();
  const activePets = useActivePets();
  const petIdsForQuantityTotal = activePets?.map((pet) => pet.id);
  const { refetchUser } = useAuth();
  const { mutateAsync: addAddon, isLoading: isAddAddonsLoading } = useAddProductToPetPlan();

  const recipeQuantities = useMemo(() => {
    if (!account || !recipes?.length || !petIdsForQuantityTotal) {
      return;
    }

    return getAddOnQuantitiesByPet(recipes, account, petIdsForQuantityTotal);
  }, [account, petIdsForQuantityTotal]);

  const [modalRecipe, setModalRecipe] = useState<RecipeFull | null>(null);
  const [isPDPOpen, setIsPDPOpen] = useState(false);
  const [isDogSelectOpen, setIsDogSelectOpen] = useState(false);

  const onPressAddOn = (recipe: RecipeFull) => {
    segment.trackEvent('Click Add-On Product Details', {
      email: account?.email,
      product_code: recipe.default_product.code,
      pet_name: null, // kept for parity with old Segment events
      account_id: account?.id,
      session_id: null,
    });
    setModalRecipe(recipe);
    setIsPDPOpen(true);
  };

  const handleAdd = async (code: string | undefined, petId: string, quantity: number) => {
    if (!code) return;
    const petData = account?.pets.find((pet) => pet.id === petId);
    if (petData) {
      const snack = recipes?.find((recipe) => recipe.default_product?.code === code);
      try {
        await addAddon({
          petPlanId: petData.pet_plan.id,
          products: [{ code, quantity: quantity + 1, recurring: true }],
        });

        await refetchUser();
        displayToast({
          message: `${snack?.name} have been added to ${petData.name}'s plan.`,
          type: ToastType.Success,
          onPress: () => navigation.push('Dogs', { petId, scrollToTop: true }),
        });
      } catch (err) {
        displayToast({
          message: `An error occurred while adding ${snack?.name} to ${petData.name}'s plan.`,
          type: ToastType.Error,
        });
        logger.error(`Error adding ${snack?.name} to ${petData.name}'s plan: ${err}`);
      }
    }
  };

  const recipePillContent: { [key: RecipeFull['id']]: string } = {
    'SN-BEEFTRAINERS': 'Puppy Favorite',
    'SN-CHICKENBITES': 'Best Seller',
    'SN-TURKEYSTRIPS': 'New',
  };

  return (
    <VToHStack
      w="100%"
      space={{ base: 0, lg: 6 }}
      justifyContent={{ base: 'center', lg: 'start' }}
      flexWrap="wrap"
    >
      {recipes?.map((recipe, i) => {
        const { unit } = ADDONS_CONFIGURATION[recipe.type as AddonsType];
        const quantity = recipeQuantities ? recipeQuantities[recipe.id] : undefined;
        const firstPetWithRecipe = getFirstPetWithAddon(account, recipe.id);
        const onAdd = () => {
          segment.trackEvent('Add-On Subscription Initiated', {
            email: account.email,
            product_code: recipe.default_product.code,
            account_id: account.id,
          });
          setModalRecipe(recipe);
          if (activePets.length > 1) {
            setIsDogSelectOpen(true);
          } else if (activePets?.length) {
            handleAdd(recipe.default_product.code, activePets[0].id, quantity ? quantity : 0);
          }
        };

        const itemRef =
          bannerRef?.recipeId === recipe.id && bannerRef?.ref ? bannerRef.ref : undefined;
        return (
          <Stack key={recipe.id + i.toString()}>
            {i === recipes.length - 1 && isMobile ? <Box ref={itemsRef} w={0} h={0} /> : null}
            <CarouselItem
              ref={itemRef}
              h="fit-content"
              maxW="100%"
              mb={{ base: 9, lg: 12 }}
              unit={unit}
              title={recipe.name}
              onPressImage={() => {
                onPressAddOn(recipe);
              }}
              price={recipe.default_product.price.price}
              quantity={quantity}
              image={`${ASSETS_URL}/pictos/${capitalize(recipe.type)}/${recipe.id}.png`}
              imageProps={{ w: '100%', h: '100%' }}
              primaryCtaProps={{ maxW: '240px' }}
              pressableProps={{
                w: { base: '100%', lg: '326px' },
                h: { base: '343px', lg: '326px' },
              }}
              isLongPressAvailable
              hoverStateImage={`${ASSETS_URL}/pictos/${capitalize(recipe.type)}/${
                recipe.id
              }-HS.png`}
              badgeMessage={
                recipePillContent[recipe.id] ? (
                  <StarBadge badgeContent={recipePillContent[recipe.id]} />
                ) : null
              }
              onAdd={onAdd}
              isLoading={isAddAddonsLoading}
              onEditQuantity={() =>
                navigation.push('Dogs', { petId: firstPetWithRecipe, scrollToTop: true })
              }
            />
          </Stack>
        );
      })}
      {modalRecipe ? (
        <PDPModal
          recipe={modalRecipe}
          buttonText="ADD TO PLAN"
          showCTA
          prices={[modalRecipe.default_product?.price.price]}
          pricingUnits={[modalRecipe.type === RecipeType.SNACK ? 'bag' : 'pack']}
          updateState={() => {
            if (activePets?.length > 1) {
              setIsDogSelectOpen(true);
            } else {
              handleAdd(
                modalRecipe.default_product?.code,
                account?.pets[0].id,
                recipeQuantities?.[modalRecipe.id] ?? 0
              );
            }
          }}
          isOpen={isPDPOpen}
          onClose={() => setIsPDPOpen(false)}
        />
      ) : null}
      {modalRecipe ? (
        <DogSelectModal
          isOpen={isDogSelectOpen}
          onClose={() => setIsDogSelectOpen(false)}
          onSuccess={() => setIsDogSelectOpen(false)}
          recipe={modalRecipe}
          petIds={activePets.map((pet) => pet.id)}
          onConfirm={addAddon}
          isLoading={isAddAddonsLoading}
          showQuantityByPet
        />
      ) : null}
    </VToHStack>
  );
};
