import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { VStack } from 'native-base';
import { useState, useEffect, useCallback } from 'react';

import { MealPlanFooter } from '../MealPlanFooter';
import { ChoosePlanSize } from './ChoosePlanSize';
import { PictosSection } from './PictosSection';
import { ReviewSection } from './ReviewSection';

import { useCallBuildPlansRegularPlan, useCallBuildTopperPlan, useSessionPlans } from '@/api';
import { FunnelScreenNames } from '@/constants';
import { useSessionQuery } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import segment from '@/segment';
import { Dog, FunnelStackParamList, PlanType } from '@/types';

export const FreshMealPlan = ({ dog }: { dog: Partial<Dog> }) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.MEAL_PLAN);
  const { isLoading: isLoadingSession, mutateUpdatePetAnswer, session } = useSessionQuery();
  const [isLoading, setIsLoading] = useState(false);
  const funnelErrorHandler = useFunnelErrorHandler();
  const discounts = session?.discounts?.map((discount) => discount.code);
  const {
    query: { data },
  } = useSessionPlans({
    pet: dog,
    discounts,
    sessionId: session?.id,
  });
  const [planType, setPlanType] = useState<PlanType | null>(null);
  const navigation = useNavigation<NavigationProp<FunnelStackParamList>>();

  const { mutateAsync: callBuildPlansRegularPlan, data: freshData } =
    useCallBuildPlansRegularPlan();

  const { mutateAsync: callBuildPlansTopperPlan, data: topperData } = useCallBuildTopperPlan();

  const buildRegularAndTopperPlans = async () => {
    await callBuildPlansRegularPlan({
      plan_size: data?.plan_size.id as number,
      recipes: dog.recipes,
      discounts: session?.discounts?.map((discount) => discount.code),
      session_id: session?.id,
    });
    await callBuildPlansTopperPlan({
      plan_size: data?.topper_plan_size.id as number,
      recipes: dog.recipes,
      discounts: session?.discounts?.map((discount) => discount.code),
      session_id: session?.id,
    });
  };

  useFocusEffect(
    useCallback(() => {
      if (data) {
        buildRegularAndTopperPlans();
      }
    }, [data, session, dog.recipes])
  );

  useEffect(() => {
    if (freshData) {
      const price = Number(freshData?.trial.price.discounted_price ?? freshData?.trial.price.price);
      const recipes = freshData?.trial.product.recipes.map((recipe) => recipe.id) || [];
      segment.trackEvent('AddToCart', {
        dogName: dog?.name,
        price,
        recipes,
        account_id: null,
        session_id: session?.id,
      });
    }
  }, [freshData]);

  const onSubmit = async (planType: 'full' | 'topper') => {
    const planData = planType === 'full' ? freshData : topperData;
    try {
      setIsLoading(true);
      await mutateUpdatePetAnswer({
        calories_per_day: planData?.calories_per_day,
        plan_size: planData?.plan_size,
        plan_type: planType,
        trial_pricing_id: planData?.trial.price.id,
        subscription_pricing_id: planData?.subscription.price.id,
      });
      navigation.navigate(nextStep);
    } catch (error) {
      funnelErrorHandler(error, 'Update fresh meal plan');
    } finally {
      setIsLoading(false);
    }
  };

  const dataIsReady = Boolean(!isLoadingSession && data && freshData && topperData);
  const chosenPlan = planType === 'full' ? freshData : topperData;
  return (
    <VStack w="100%" alignItems="center">
      <ChoosePlanSize
        dataIsReady={dataIsReady}
        dog={dog}
        planType={planType}
        freshData={freshData}
        topperData={topperData}
        setPlanType={(planType: PlanType) => {
          setPlanType(planType);
        }}
      />
      <PictosSection />
      <ReviewSection />
      <MealPlanFooter
        data={chosenPlan}
        isLoading={isLoading}
        onSubmit={() => {
          if (planType) {
            onSubmit(planType);
          }
        }}
      />
    </VStack>
  );
};
