import { Stack, Heading, Divider, IStackProps, HStack, Badge, Text } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image, Stepper } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { capitalize, getRecipesProductImageSrc, round } from '@/utils';

export interface LineItemPlanCardExtraProps extends IStackProps {
  product: OrderProduct;
  disabled?: boolean;
  isLoading: boolean;
  showDivider?: boolean;
  showStepper?: boolean;
  handlePressStepper?: (...args: any[]) => void;
}

export const LineItemPlanCardExtra = ({
  product,
  disabled,
  isLoading,
  showDivider,
  showStepper,
  handlePressStepper,
  ...props
}: LineItemPlanCardExtraProps) => {
  const productIsFree = product.unit_price === undefined || Number(product.unit_price) === 0;
  const isMerch = product.product_type === ProductType.MERCH;
  const imageUri = isMerch
    ? `${ASSETS_URL}/pictos/PDP/${capitalize(product.product_type)}/${product.code}/0.png`
    : getRecipesProductImageSrc(product.recipes, false);

  return (
    <HStack space={{ base: 2, lg: 4 }} {...props}>
      <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
        <Image
          source={{ uri: imageUri }}
          w="100%"
          h="100%"
          alt={product.name}
          resizeMode="contain"
          opacity={disabled ? 50 : undefined}
        />
      </Stack>
      <Stack flex={3} space={2} justifyContent="start" alignItems="start">
        <HStack w="100%" alignItems="center" justifyContent="space-between">
          <Heading
            size="bodyMdToLg"
            fontWeight="bold"
            color={disabled ? 'sntGrey.primary' : undefined}
          >
            {product.name}
          </Heading>
          {!productIsFree ? (
            <Badge
              variant="smallInfoPillSecondary"
              _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
              borderColor={disabled ? 'sntGrey.primary' : undefined}
            >
              {`$${round(product.quantity * Number(product.unit_price), 2)}`}
            </Badge>
          ) : null}
        </HStack>
        {isMerch ? (
          showStepper && !productIsFree ? (
            <Stepper
              space={{ base: 3, lg: 4 }}
              controlledQuantity={product.quantity}
              onPressDec={() => handlePressStepper?.(product.code, product.name, 'decrease')}
              onPressInc={() => handlePressStepper?.(product.code, product.name, 'increase')}
              isDisabled={disabled || isLoading}
              disabledPlus={isLoading}
              disabledMinus={isLoading}
            />
          ) : (
            <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
              {product.quantity} item
              {product.quantity !== 1 && 's'}
            </Text>
          )
        ) : null}
        {showDivider ? <Divider bgColor="gallery" w="85%" mt={4} /> : null}
      </Stack>
    </HStack>
  );
};
