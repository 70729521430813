import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Center, Stack, Text } from 'native-base';
import { useEffect } from 'react';

import { FormSubmitButton } from '../FormSubmitButton';
import { RecipeSelectForm } from '../Home/ManageDogPlans/RecipeSelectForm';
import { useRecipeSelectForm } from '../Home/ManageDogPlans/useRecipeSelectForm';

import { useUpdatePetPlan } from '@/api';
import { LoadingSpinner, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import { ProtectedStackParamList } from '@/types';
import { sendErrorReport } from '@/utils/analytics';

export const DogSelectRecipe = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'DogSelectRecipe'>) => {
  const account = useAccount();
  const { pets } = account;

  const { formState, setValue, getValues, watch, reset } = useRecipeSelectForm();
  const { isDirty } = formState;
  const selectedPlanRecipeIds = watch('recipeIds');

  const pet = pets.find((pet) => pet.id === route.params?.petId);

  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();

  useEffect(() => {
    if (pet) {
      navigation.setOptions({ title: `Change ${pet.name}'s Recipes` });
    }
  }, [account, navigation, pet]);

  if (!pet || !route.params?.foodType) {
    return <LoadingSpinner accessLabel="Change Recipe Page" />;
  }

  const onSubmit = async () => {
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: { recipes: selectedPlanRecipeIds },
      });
      displayToast({
        message: `${pet.name}'s food type and recipes have been successfully updated.`,
      });
      navigation.navigate(
        route.params?.successRedirect || 'Dogs',
        route.params?.successRedirectParams || {}
      );
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <Stack alignItems="center" w="100%" px="16px" pb="24px">
      <Stack w="100%" justifyContent="space-between">
        <Stack mb={5}>
          <Center mb={2}>
            <Text size="bodySm">Choose one, or mix and match for more variety!</Text>
          </Center>
          <RecipeSelectForm
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            pet={pet}
            recipeType={route.params?.foodType}
          />
        </Stack>
        <Stack alignItems="center">
          <FormSubmitButton
            onPress={onSubmit}
            isLoading={isLoadingUpdatePetPlan}
            isDisabled={!isDirty || selectedPlanRecipeIds.length === 0}
          >
            Save Changes
          </FormSubmitButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
