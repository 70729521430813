import { Divider, Stack, VStack } from 'native-base';
import React, { useMemo, useState } from 'react';
import { Linking } from 'react-native';

import { AddOnCarousel } from '../..';
import DeceasedDogsView from '../../DeceasedDogsView';
import { OnboardingModal } from '../../OnboardingModal';
import { PetsAvatarPressableList } from '../PetsAvatarPressableList';
import HomeSkeleton from './HomeSkeleton';
import HomeTabInactiveDogsView from './HomeTabInactiveDogsView';
import { ShopBanner } from './ShopBanner';

import { PetStatus } from '@/api';
import { CurrentOrdersCards, MarketingCard, PortalWrapper } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import { useRecommendedAddons } from '@/hooks/useRecommendedAddons';
import {
  checkIfAllPetsAreDeceased,
  isRecentPastOrder,
  sortOrderByChargedOrScheduled,
  sortOrderByScheduled,
} from '@/utils';

const CHECK_IT_OUT_LINK = 'https://whatthepup.spotandtango.com/';

export const HomeTab = () => {
  const account = useAccount();
  const availableAddons = useRecommendedAddons();

  const [showOnboardingModal, setShownOnboardingModal] = useState(true);

  const pets = account.pets;
  const orders = account.orders;

  const recentPastOrders = orders.past.filter(isRecentPastOrder);
  const currentOrders = [...recentPastOrders, ...orders.processing].sort(
    sortOrderByChargedOrScheduled
  );
  const upcomingOrders = orders.upcoming.sort(sortOrderByScheduled);

  const handleCheckItOut = () => {
    Linking.openURL(CHECK_IT_OUT_LINK);
  };

  const allPetsAreDeceased = checkIfAllPetsAreDeceased(pets);
  const allPetsAreInactive = pets.every(
    (pet) => pet.status === PetStatus.PAUSED || pet.status === PetStatus.CANCELLED
  );

  const isEligibleForOnboarding = useMemo(() => {
    const impersonateToken = localStorage.getItem('impersonate_token');
    return !account.viewed_portal_tutorial && account.is_migrated && !impersonateToken;
  }, [account]);

  // TODO: Remove after Shop Tab launch
  const showShopTab = process.env.SHOW_SHOP_TAB === 'true';
  const carouselTitle = showShopTab
    ? 'S&T Shop — Bestsellers'
    : `Recommended for ${account.pets?.length === 1 ? account.pets[0].name : 'You'}`;
  const carouselDescription = showShopTab ? 'Exclusively for Spot & Tango subscribers' : undefined;

  return (
    <PortalWrapper
      containerProps={{
        maxW: '100%',
        w: { base: '100%' },
        space: { base: 4, lg: 12 },
        px: 0,
        pb: 0,
        pt: showShopTab ? 0 : undefined,
      }}
    >
      {showShopTab && account && !allPetsAreDeceased && !allPetsAreInactive && <ShopBanner />}
      {isEligibleForOnboarding && showOnboardingModal && (
        <OnboardingModal onClose={() => setShownOnboardingModal(false)} />
      )}
      <Stack w="100%" maxW={{ base: '100%', lg: '840px' }} px={4}>
        {!account && <HomeSkeleton />}
        {account && allPetsAreDeceased && <DeceasedDogsView />}
        {account && allPetsAreInactive && <HomeTabInactiveDogsView />}
        {account && !allPetsAreDeceased && !allPetsAreInactive && (
          <VStack w="100%" alignItems="center" space={{ base: '16px', lg: '24px' }}>
            <VStack w={{ base: '100%', lg: '840px' }}>
              <CurrentOrdersCards
                orders={{ processing: currentOrders, upcoming: upcomingOrders }}
                pets={pets}
              />
            </VStack>
            <AddOnCarousel
              availableAddons={availableAddons}
              title={carouselTitle}
              description={carouselDescription}
              showEditQuantity
            />
            <Stack
              w={{
                base: '100%',
                lg: '840px',
              }}
              space={{ base: '16px', lg: '24px' }}
            >
              {Boolean(availableAddons?.length) && <Divider bg="gallery" />}
              <PetsAvatarPressableList />
            </Stack>
          </VStack>
        )}
      </Stack>
      <Stack
        justifyContent={{ base: 'center' }}
        alignItems="center"
        alignSelf="center"
        w="100%"
        h="fit-content"
        pt={{ base: '24px', lg: '48px' }}
        pb={{ base: '16px', lg: '24px' }}
        px={{ base: '16px', lg: '0px' }}
        direction={{ base: 'column', lg: 'row' }}
        space={{ base: '16px', lg: '24px' }}
        bg="sntBlue.primary"
      >
        <MarketingCard
          header="Want advice from our experts?"
          content="We’ve got you covered with top tips from vets, trainers, and more! Plus we share dog-friendly guides, pet-parent must-haves, and funny stories!"
          source={`${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_12131.png`}
          ctaText="CHECK IT OUT"
          variant="cta"
          w={{ base: '100%', lg: '408px' }}
          justifyContent="center"
          alignItems="center"
          space={8}
          contentTextProps={{ minH: '64px' }}
          imageProps={{ h: '387px' }}
          ctaOnPress={handleCheckItOut}
        />
        <MarketingCard
          header="Welcome to our community!"
          content="Become part of our pet parent community! Get advice from experts, learn from fellow dog owners, and be the first to know about exclusives, VIP events, and new product launches."
          source={`${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_20681.png`}
          ctaText=""
          variant="social"
          w={{ base: '100%', lg: '408px' }}
          justifyContent="center"
          alignItems="center"
          imageProps={{ h: '387px' }}
        />
      </Stack>
    </PortalWrapper>
  );
};
