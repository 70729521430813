import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Heading, Flex, Box, Spacer, Button, Text, HStack, VStack, Stack } from 'native-base';
import { useCallback, useEffect, useState } from 'react';

import { useSessionRecipeOptions } from '@/api';
import { PetGender, PlanData, RecipeType } from '@/api/types';
import { PDPModal, RecipeCard } from '@/components/Elements';
import { Head } from '@/components/Head';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames, RecipeCardBanners } from '@/constants';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { Dog, MealRecipeType } from '@/types/FunnelSession';
import { isPup } from '@/utils';
import { getCurrentPet } from '@/utils/getCurrentPet';

const DEFAULT_FOOD_TYPE = RecipeType.UNKIBBLE;
const BREAKPOINT_PLAN_SIZE = 300;

const configStylesForContainers = {
  UNKIBBLE: {
    stackH: { base: '90%', '2xl': 'auto' },
    vStackMaxW: { base: '100%', md: '696px', '2xl': '1400px' },
  },
  FRESH: {
    stackH: { base: '90%', '2xl': 'auto' },
    vStackMaxW: { base: '100%', md: '696px', xl: '100%', '2xl': '1400px' },
  },
};

const getRecommendationCopy = (dog: Partial<Dog>) => {
  const foodType = dog?.food_type || DEFAULT_FOOD_TYPE;
  const dogName = dog.name;
  const dogIsPup = isPup(dog.birth_year, dog.birth_month);
  const pronoun = dog?.gender === PetGender.MALE ? 'he' : 'she';
  if (foodType === DEFAULT_FOOD_TYPE) {
    if (!dogIsPup) {
      if (dog.plan_size && dog.plan_size >= BREAKPOINT_PLAN_SIZE) {
        return `We recommend any of the following four recipes for ${dogName}. You can choose one, or mix and match for more variety!`;
      }
      return `We recommend any of the following four recipes for ${dogName}. After your trial, you can add multiple recipes to ${dogName}'s plan for more variety!`;
    }
    return `UnKibble is a puppy favorite! We recommend any of the following four recipes for ${dogName} as ${pronoun} grows. After your trial, you can add multiple recipes to ${dogName}'s plan for more variety!`;
  } else {
    return dogIsPup
      ? `All of our recipes are formulated and balanced for all ages, including your growing puppy. You can choose one or mix and match for more variety!`
      : `We recommend any of the following recipes for ${dogName}. You can choose one or mix and match for more variety!`;
  }
};

export default function Recipes({ navigation }: FunnelScreenProps<'Recipes'>) {
  const isFocused = useIsFocused();
  const nextStep = useNextFunnelStep(FunnelScreenNames.RECIPES);
  const { session: funnelSession, mutateUpdatePetAnswer } = useSessionQuery();
  const [isLoading, setIsLoading] = useState(false);
  const funnelErrorHandler = useFunnelErrorHandler();
  const discounts = funnelSession?.discounts?.map((discount) => discount.code);

  const dog = getCurrentPet(funnelSession);
  const foodType = dog?.food_type || DEFAULT_FOOD_TYPE;

  const { stackH, vStackMaxW } = configStylesForContainers[foodType];

  const { data: planOptionsData, isFetching: isLoadingRecipes } = useSessionRecipeOptions({
    pet: dog,
    discounts,
    sessionId: funnelSession?.id,
  });
  const recipeOptionsList = dog?.food_type && planOptionsData?.plans[dog?.food_type];

  const [showPDPModal, setShowPDPModal] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanData>();
  const [maxRecipesAllowed, setMaxRecipesAllowed] = useState<number>(1);
  const [selectedPlanCodes, setSelectedPlanCodes] = useState<string[]>([]);
  const [recipeCountDescription, setRecipeCountDescription] = useState('');

  const selectedPlans = recipeOptionsList?.filter(({ trial: { product } }) =>
    selectedPlanCodes.includes(product.code)
  );

  useEffect(() => {
    if (dog && planOptionsData) {
      setMaxRecipesAllowed(planOptionsData.maxRecipes[dog.food_type as MealRecipeType]);
      setRecipeCountDescription(dog?.food_type === RecipeType.UNKIBBLE ? 'two UnKibble' : 'three');
    }
  }, [dog, planOptionsData]);

  const onSubmit = async () => {
    if (!selectedPlans) {
      return;
    }

    const selectedRecipesIds = selectedPlans
      .map((sub) => sub.trial.product.recipes.map((recipe) => recipe.id))
      .flat();

    try {
      setIsLoading(true);
      await mutateUpdatePetAnswer({
        recipes: selectedRecipesIds,
      });
      navigation.navigate(nextStep);
    } catch (error) {
      funnelErrorHandler(error, 'Update recipes');
    } finally {
      setIsLoading(false);
    }
  };

  const togglePlan = (code: string) => {
    const planIndex = selectedPlanCodes.findIndex((selectedPlanCode) => code === selectedPlanCode);

    // if plan is not selected and we have not reached the max number of recipes allowed
    // add the plan to the selected plans
    if (planIndex === -1 && selectedPlanCodes.length < maxRecipesAllowed) {
      setSelectedPlanCodes((previousPlanCodes) => [...previousPlanCodes, code]);
    } else if (planIndex > -1) {
      // if plan is selected, remove it from the selected plans
      setSelectedPlanCodes(
        selectedPlanCodes.filter((selectedPlanCode) => selectedPlanCode !== code)
      );
    }
    // otherwise do nothing since we have reached the max number of recipes allowed
  };

  useFocusEffect(
    useCallback(() => {
      // if we're returning to this page, select any recipes that were submitted
      if (dog?.recipes && dog.recipes.length > 0 && recipeOptionsList) {
        const previouslySelectedCodes = recipeOptionsList
          .map((recipeData) => {
            const code = recipeData.trial.product.code;
            const petHasRecipe = !!dog.recipes?.find((recipe) => code.includes(recipe));
            return petHasRecipe ? code : undefined;
          })
          .filter(Boolean) as string[];
        setSelectedPlanCodes(previouslySelectedCodes);
      }
    }, [dog, recipeOptionsList])
  );

  if (!isFocused || !dog || !Object.keys(dog).length) {
    return null;
  }

  return (
    <FunnelWrapper
      maxW="100%"
      px={{ base: '0', md: '0px' }}
      hasHeader={false}
      h="100%"
      containerProps={{ px: 0, pt: 0, maxW: '100%' }}
    >
      <Stack h={stackH} overflow="scroll" w="100%" alignItems="center">
        <Head />
        <Flex justify="center" align="center" px={5}>
          <Heading
            fontWeight="bold"
            size="titleSmToMd"
            textAlign="center"
            w={{ base: '90%', md: '100%' }}
          >
            {maxRecipesAllowed > 1
              ? `Choose up to ${recipeCountDescription} recipes for ${dog?.name}`
              : `Choose ${dog?.name}'s favorite recipe`}
          </Heading>
          <Spacer size={{ base: 2, lg: 4 }} />

          <Text
            fontFamily="secondary"
            fontWeight="medium"
            size="bodySmToMd"
            textAlign="center"
            maxW={{ base: '100%', md: '960px' }}
          >
            {getRecommendationCopy(dog)}
          </Text>
        </Flex>
        <VStack
          flexDirection={{ base: 'column', lg: 'row' }}
          flexWrap="wrap"
          w={{ base: '100%', lg: 'auto' }}
          maxW={vStackMaxW}
          px={{ base: 5, md: 0 }}
          py="36px"
        >
          {!isLoadingRecipes
            ? recipeOptionsList?.map((plan, key) => {
                const {
                  trial: { product, price },
                } = plan;
                const recipe = product.recipes[0];

                return (
                  <Box key={key} alignItems="center">
                    <RecipeCard
                      recipe={recipe}
                      w={{ base: '100%', md: '484px', lg: '324px' }}
                      h={{ base: '142px', md: '175px', lg: 'inherit' }}
                      minH={{ base: '142px', md: '175px', lg: '378px' }}
                      key={key + recipe.id}
                      pricing={
                        price.discounted_price_per_meal
                          ? price.discounted_price_per_meal
                          : price.price_per_meal
                      }
                      selected={selectedPlanCodes?.includes(product.code)}
                      onPress={() => togglePlan(product.code)}
                      underlineButton={{
                        text: 'View Details',
                        onPress: () => {
                          setShowPDPModal(true);
                          setPlanDetails(plan.trial);
                        },
                      }}
                      bannerText={RecipeCardBanners[recipe.id]?.funnel}
                      isTrial
                    />
                  </Box>
                );
              })
            : null}
        </VStack>
      </Stack>
      <HStack
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        w={{ base: '100%', md: '60%', lg: 'fit-content' }}
        my={4}
        px="10px"
      >
        <Button
          size="bodyMdToLg"
          w="100%"
          maxW={{ base: '100%', md: '290px' }}
          h={{ base: '52px', md: '56px' }}
          isDisabled={!selectedPlanCodes || selectedPlanCodes.length === 0}
          isLoading={isLoading}
          onPress={() => onSubmit()}
        >
          Continue
        </Button>
      </HStack>
      <>
        {planDetails && (
          <PDPModal
            recipe={planDetails.product.recipes[0]}
            buttonText="Select Recipe"
            prices={[
              planDetails.price.discounted_price_per_meal !== null
                ? planDetails.price.discounted_price_per_meal
                : planDetails.price.price_per_meal,
              planDetails.price.discounted_price_per_week !== null
                ? planDetails.price.discounted_price_per_week
                : planDetails.price.price_per_week,
            ]}
            pricingUnits={['meal', 'week']}
            isTrial
            updateState={() => togglePlan(planDetails.product.code)}
            isOpen={showPDPModal}
            onClose={() => setShowPDPModal(false)}
            // showCTA={selectedPlanCodes.length < maxRecipesAllowed} // PDP modal CTAs are being hidden for now -- PRODUCT-1768
          />
        )}
      </>
    </FunnelWrapper>
  );
}
